<template>
  <b-modal
    id="holiday-form-modal"
    ref="holiday-form-modal"
    @hidden="cleanErrors"
    hide-footer
    :title="findModalTitle()"
  >
    <template>
      <b-form @submit.stop.prevent="saveHoliday()">
        <div v-show="this.errors.COMMON" class="mb-3">
          <span class="text-error">{{ this.errors.COMMON }}</span>
        </div>

        <b-form-group id="country" label="Country" label-for="country">
          <multiselect
            v-model="holidayForm.country"
            id="countrySelect"
            placeholder="Choose country..."
            :options="Object.keys(countries)"
            :customLabel="(country) => this.countries[country]"
            :allow-empty="false"
            deselect-label=""
          />
          <span class="text-error" v-show="this.errors.country">{{
            this.errors.country
          }}</span>
        </b-form-group>

        <b-form-group label="Holiday name" label-for="name">
          <b-form-input id="name" v-model="holidayForm.name" />
          <span class="text-error" v-show="this.errors.name">{{
            this.errors.name
          }}</span>
        </b-form-group>

        <div class="mb-4">
          <div class="mb-2">Start date</div>
          <b-form-datepicker
            id="startDate"
            v-model="holidayForm.startDate"
            :max="holidayForm.endDate"
          ></b-form-datepicker>
          <span class="text-error" v-show="this.errors.startDate">{{
            this.errors.startDate
          }}</span>
        </div>

        <div class="mb-4">
          <div class="mb-2">End date</div>
          <b-form-datepicker
            id="endDate"
            v-model="holidayForm.endDate"
            :min="holidayForm.startDate"
          ></b-form-datepicker>
          <span class="text-error" v-show="this.errors.endDate">{{
            this.errors.endDate
          }}</span>
        </div>

        <b-button
          size="sm"
          variant="success"
          type="submit"
          class="float-right"
          test-id="save-holiday-modal-submit"
          >Submit
        </b-button>
      </b-form>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import { supportedCountries } from "@/utils/countryUtils";
import { createHoliday, updateHoliday } from "@/api/HolidaysApi";
import { handleApiErrors } from "@/utils/errorUtils";

export default {
  props: {
    holidayForm: {
      default: () => {
        return {
          id: null,
          name: null,
          country: null,
          startDate: null,
          endDate: null,
        };
      },
    },
    saveSuccessCallback: {
      default: () => {},
    },
    showErrorMessage: {
      default: () => {},
    },
  },
  components: {
    multiselect: Multiselect,
  },
  name: "holidayFormModal",
  data() {
    return {
      countries: supportedCountries,
      errors: this.emptyErrors(),
    };
  },
  methods: {
    emptyErrors() {
      return { country: null, name: null, startDate: null, endDate: null };
    },
    cleanErrors() {
      this.errors = this.emptyErrors();
    },
    findModalTitle() {
      return this.holidayForm.id ? "Update holiday" : "Create holiday";
    },
    saveHoliday() {
      let promise;
      if (this.holidayForm.id) {
        promise = updateHoliday(this.holidayForm.id, this.holidayForm);
      } else {
        promise = createHoliday(this.holidayForm);
      }

      promise.then(this.saveSuccessCallback).catch(this.handleErrors);
    },
    handleErrors(error) {
      const apiErrors = handleApiErrors(error);
      if (Object.keys(apiErrors).length !== 0) {
        this.errors = apiErrors;
      } else {
        this.showErrorMessage();
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__option--highlight,
.multiselect__option--highlight::after {
  background: #0fac5f;
}

.multiselect__option--selected.multiselect__option--highlight,
.multiselect__option--selected.multiselect__option--highlight::after {
  background: #374454;
}
</style>
