var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":'holidaysTable-' + _vm.country}},[_c('div',_vm._l((Object.keys(_vm.holidays || {})),function(year){return _c('span',{key:year},[_c('b-button',{class:'ml-2 ' +
          (_vm.activeYear === year
            ? 'btn--primary'
            : 'text-success btn-secondary'),on:{"click":function($event){return _vm.changeActiveYear(year)}}},[_vm._v(_vm._s(year))])],1)}),0),(_vm.activeYear)?_c('v-card',{staticClass:"mt-3"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"fields":_vm.headers,"items":_vm.holidays[_vm.activeYear] || [],"disable-pagination":"","disable-filtering":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.endDate))+" ")]}},{key:"item.id",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"clickable ml-5 icon-bigger icon-grey",attrs:{"title":"Edit holiday"},on:{"click":function($event){return _vm.openUpdateHolidayModal(item)}}},[_c('font-awesome-icon',{staticClass:"clickable ml-5 icon-bigger icon-grey",attrs:{"icon":"edit"}})],1),_c('span',{staticClass:"clickable ml-2 icon-bigger icon-red",attrs:{"title":"Delete holiday"},on:{"click":function($event){return _vm.openDeleteHolidayModal(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1)]}}],null,false,3305915368)})],1):_c('div',{staticClass:"mt-3"},[_vm._v("No holidays yet!")]),_c('ConfirmationModal',{attrs:{"modal-name":_vm.confirmDeleteHolidayModalNamePrefix + _vm.country,"title":"Remove holiday","callbackAction":_vm.removeHoliday}},[_vm._v(" You are about to delete "+_vm._s(_vm.selectedHoliday.name)+" in "+_vm._s(_vm.countries[_vm.selectedHoliday.country])+" ("+_vm._s(_vm.formatDate(_vm.selectedHoliday.startDate))+" - "+_vm._s(_vm.formatDate(_vm.selectedHoliday.endDate))+"). Are you sure you want to do this? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }