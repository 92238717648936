<template>
  <div class="mt-4 mb-4">
    <div class="text-right">
      <b-button
        test-id="open-holiday-modal-button"
        size="sm"
        class="text-success border border-success"
        @click="openHolidayModal()"
        >Add holiday
      </b-button>
    </div>
    <div
      class="mt-3 card"
      v-for="[countryKey, country] in Object.entries(countries)"
      :key="countryKey"
      :id="`${countryKey}-div`"
    >
      <div class="m-3">
        <ExpandableComponent>
          <template #header
            ><h3 class="mb-3 inline">{{ country }}</h3></template
          >

          <template>
            <HolidayTable
              :country="countryKey"
              :openHolidayFormCallback="openHolidayModal"
              :removeHolidayCallback="removeHoliday"
              :holidays="groupedHolidays[countryKey] || {}"
            />
          </template>
        </ExpandableComponent>
      </div>
    </div>

    <HolidayFormModal
      :holiday-form="holidayForm"
      :saveSuccessCallback="saveHolidayCallback"
      :showErrorMessage="showErrorMessage"
    />
  </div>
</template>

<script>
import { supportedCountries } from "@/utils/countryUtils";
import { deleteHoliday, getHolidays } from "@/api/HolidaysApi";
import HolidayTable from "@/components/holidays/HolidayTable.vue";
import HolidayFormModal from "@/components/holidays/HolidayFormModal.vue";
import ExpandableComponent from "@/components/utils/ExpandableComponent.vue";
import {
  getTimeFromStringDate,
  getYearFromStringDate,
} from "@/utils/dateUtils";

export default {
  name: "holidaysManagement",
  components: {
    ExpandableComponent,
    HolidayFormModal,
    HolidayTable,
  },
  data() {
    return {
      countries: supportedCountries,
      holidays: [],
      holidayFormModalName: "holiday-form-modal",
      holidayForm: {},
    };
  },
  computed: {
    groupedHolidays() {
      return this.groupHolidays(this.holidays);
    },
  },
  methods: {
    fetchHolidays() {
      getHolidays().then((response) => {
        this.holidays = response.data;
      });
    },
    groupHolidays(holidays) {
      if (!holidays) {
        return {};
      }
      const groupedByCountry = Object.groupBy(
        holidays,
        ({ country }) => country
      );
      Object.keys(groupedByCountry).forEach((country) => {
        groupedByCountry[country] = groupedByCountry[country]
          .sort(
            (a, b) =>
              getTimeFromStringDate(a.startDate) -
              getTimeFromStringDate(b.startDate)
          )
          .reduce((acc, holiday) => {
            const startYear = getYearFromStringDate(holiday.startDate);
            const endYear = getYearFromStringDate(holiday.endDate);

            this.addKeyAndPush(acc, startYear, holiday);
            if (startYear !== endYear) {
              this.addKeyAndPush(acc, endYear, holiday);
            }
            return acc;
          }, {});
        groupedByCountry[country] = this.sortByKey(groupedByCountry[country]);
      });
      return groupedByCountry;
    },
    addKeyAndPush(obj, key, element) {
      if (!obj[key]) {
        obj[key] = [];
      }
      obj[key].push(element);
    },
    sortByKey(unordered) {
      return Object.keys(unordered)
        .sort()
        .reverse()
        .reduce((obj, key) => {
          obj[key] = unordered[key];
          return obj;
        }, {});
    },
    openHolidayModal(holiday) {
      this.holidayForm = Object.assign({}, holiday || {});
      this.$bvModal.show(this.holidayFormModalName);
    },
    saveHolidayCallback() {
      this.fetchHolidays();
      this.showSuccessMessage();
      this.$bvModal.hide(this.holidayFormModalName);
    },
    removeHoliday(id) {
      return deleteHoliday(id)
        .then(() => {
          this.fetchHolidays();
          this.showSuccessMessage();
        })
        .catch(() => this.showErrorMessage());
    },
    showSuccessMessage() {
      this.$emit("send-message", true);
    },
    showErrorMessage() {
      this.$emit("send-message", false);
    },
  },
  beforeMount() {
    this.fetchHolidays();
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
<style>
.inline {
  display: inline;
}
</style>
