<template>
  <div>
    <div @click="toggleExpanded">
      <span class="mr-2 clickable">
        <font-awesome-icon
          v-if="expanded"
          icon="chevron-up"
        ></font-awesome-icon>
        <font-awesome-icon v-else icon="chevron-down"></font-awesome-icon>
      </span>
      <slot name="header"></slot>
    </div>

    <div v-show="expanded" class="mt-3">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

library.add(faChevronUp, faChevronDown);

export default {
  props: {
    initiallyExpanded: {
      default: false,
    },
  },
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  data() {
    return {
      expanded: this.initiallyExpanded,
    };
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
