<template>
  <v-app :id="'holidaysTable-' + country">
    <div>
      <span v-for="year in Object.keys(holidays || {})" :key="year">
        <b-button
          :class="
            'ml-2 ' +
            (activeYear === year
              ? 'btn--primary'
              : 'text-success btn-secondary')
          "
          @click="changeActiveYear(year)"
          >{{ year }}</b-button
        >
      </span>
    </div>

    <v-card class="mt-3" v-if="activeYear">
      <v-data-table
        :headers="headers"
        :fields="headers"
        :items="holidays[activeYear] || []"
        disable-pagination
        disable-filtering
        hide-default-footer
      >
        <template v-slot:item.startDate="{ item }">
          {{ formatDate(item.startDate) }}
        </template>

        <template v-slot:item.endDate="{ item }">
          {{ formatDate(item.endDate) }}
        </template>

        <template v-slot:item.id="{ item }">
          <span
            title="Edit holiday"
            class="clickable ml-5 icon-bigger icon-grey"
            @click="openUpdateHolidayModal(item)"
            ><font-awesome-icon
              icon="edit"
              class="clickable ml-5 icon-bigger icon-grey"
            />
          </span>
          <span
            title="Delete holiday"
            class="clickable ml-2 icon-bigger icon-red"
            @click="openDeleteHolidayModal(item)"
            ><font-awesome-icon icon="trash" />
          </span>
        </template>
      </v-data-table>
    </v-card>
    <div class="mt-3" v-else>No holidays yet!</div>

    <ConfirmationModal
      :modal-name="confirmDeleteHolidayModalNamePrefix + country"
      title="Remove holiday"
      :callbackAction="removeHoliday"
    >
      You are about to delete {{ selectedHoliday.name }} in
      {{ countries[selectedHoliday.country] }} ({{
        formatDate(selectedHoliday.startDate)
      }}
      - {{ formatDate(selectedHoliday.endDate) }}). Are you sure you want to do
      this?
    </ConfirmationModal>
  </v-app>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import ConfirmationModal from "../utils/ConfirmationModal.vue";
import { supportedCountries } from "@/utils/countryUtils";

library.add(faTrash, faEdit);

export default {
  props: [
    "holidays",
    "country",
    "openHolidayFormCallback",
    "removeHolidayCallback",
  ],
  watch: {
    holidays: function (newVal, oldVal) {
      const years = Object.keys(newVal);
      if (years.length === 0) {
        this.activeYear = null;
        return;
      }

      if (!this.activeYear || !years.includes(this.activeYear)) {
        this.activeYear = years[years.length - 1];
      }
    },
  },
  components: {
    "font-awesome-icon": FontAwesomeIcon,
    ConfirmationModal,
  },
  name: "holidaysTable",
  data() {
    return {
      moment: moment,
      activeYear: null,
      confirmDeleteHolidayModalNamePrefix: "confirm-delete-holiday-modal-",
      countries: supportedCountries,
      selectedHoliday: {},
      headers: [
        {
          text: "Holiday name",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Start",
          value: "startDate",
        },
        {
          text: "End",
          value: "endDate",
        },
        {
          text: "",
          value: "id",
          align: "end",
        },
      ],
    };
  },
  methods: {
    formatDate(dateString) {
      return moment(dateString).format("DD.MM.YYYY");
    },
    changeActiveYear(year) {
      this.activeYear = year;
    },
    openUpdateHolidayModal(item) {
      this.openHolidayFormCallback(item);
    },
    openDeleteHolidayModal(item) {
      this.selectedHoliday = item;
      this.$bvModal.show(
        this.confirmDeleteHolidayModalNamePrefix + this.country
      );
    },
    removeHoliday() {
      this.removeHolidayCallback(this.selectedHoliday.id).finally(() =>
        this.$bvModal.hide(
          this.confirmDeleteHolidayModalNamePrefix + this.country
        )
      );
    },
  },
};
</script>

<style scoped src="@/assets/css/energia.min.css"></style>
<style>
.v-application--wrap {
  min-height: 0;
}

.icon-bigger {
  font-size: 1.3rem;
}

.icon-grey {
  color: grey;
}

.icon-red {
  color: #ff4d4d;
}
</style>
