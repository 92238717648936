import { OCPP_API } from "./common/http-common";

export function getHolidays() {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.get(`/${tenant}/masterdata/v1.0/holidays`);
}

export function createHoliday(holiday) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.post(`/${tenant}/masterdata/v1.0/holidays`, holiday);
}

export function updateHoliday(id, holiday) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.put(`/${tenant}/masterdata/v1.0/holidays/${id}`, holiday);
}

export function deleteHoliday(id) {
  const tenant = process.env.VUE_APP_TENANT;
  return OCPP_API.delete(`/${tenant}/masterdata/v1.0/holidays/${id}`);
}
