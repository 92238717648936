<template>
  <div class="mr-5 ml-5 mt-4 fontStyle">
    <div>
      <div
        class="d-flex align-items-center p-3 my-3 text-white-50 rounded bg-charger-box"
      >
        <div class="lh-100">
          <h6 class="mb-0 text-white lh-100">Holidays management</h6>
        </div>
      </div>
      <HolidaysManagement @send-message="showMessage" />

      <div class="m-3 p-2 fontStyle w-50">
        <b-alert
          class="fixed-top"
          :show="messageCountDown"
          dismissible
          variant="success"
          @dismissed="messageCountDown = 0"
        >
          <p>Changes saved!</p>
        </b-alert>
        <b-alert
          class="fixed-top"
          :show="errorMessageCountDown"
          dismissible
          variant="danger"
          @dismissed="errorMessageCountDown = 0"
        >
          <p>Changes not saved!</p>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import HolidaysManagement from "@/components/holidays/HolidaysManagement.vue";
export default {
  name: "HolidaysManagementView",
  components: {
    HolidaysManagement,
  },
  data() {
    return {
      constants: {
        MESSAGE_COUNTDOWN_SECONDS: 5,
      },
      messageCountDown: 0,
      errorMessageCountDown: 0,
    };
  },
  methods: {
    showSuccessMessage() {
      this.messageCountDown = this.constants.MESSAGE_COUNTDOWN_SECONDS;
    },
    showErrorMessage() {
      this.errorMessageCountDown = this.constants.MESSAGE_COUNTDOWN_SECONDS;
    },
    showMessage(wasSuccessful) {
      return wasSuccessful
        ? this.showSuccessMessage()
        : this.showErrorMessage();
    },
  },
};
</script>
<style scoped src="@/assets/css/energia.min.css"></style>
